import { useState, memo, useEffect} from "react";
import  {
  PieChartOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  SettingOutlined,
  AppstoreOutlined,
  MailOutlined,
  FormOutlined,
  DatabaseOutlined,
  BorderOutlined,
  VerifiedOutlined,
} from "@ant-design/icons";
import { Badge, Layout, Menu, Space, Typography } from "antd";
import { Style } from "./style";
import { useLocation, Link, useOutlet } from "react-router-dom";
import {
  ROUTE_DASHBOARD,
  ROUTE_USERS,
  ROUTE_ITEMS,
  ROUTE_COLLECTIONS,
  ROUTE_NEWSLETTERS,
  ROUTE_BLOGCATEGORIES,
  ROUTE_BLOGLIST,
  ROUTE_FEESCONFIG,
  ROUTE_REPORT,
  ROUTE_SUBSCRIBERS,
  ROUTE_SETTING_TERM_OF_USE,
  ROUTE_SETTING_PRIVACY_POLICY,
  ROUTE_SETTING_ACTIVITY_LOG,
  ROUTE_CATEGORIES,
  ROUTE_VERIFICATION_REQUESTS_ACCOUNT, ROUTE_VERIFICATION_SETTINGS_ACCOUNT_EDIT, 
  ROUTE_WHITELIST
} from "routes/routesAddress";
import { getRequest } from "helpers/api";
import { GET_NOTIFICATION_API_URL } from "./const";

const { Content, Footer, Sider } = Layout;
const { Title } = Typography;

export default memo(function MainLayout(props) {
  const location = useLocation();
  const outlet = useOutlet();
  const [collapsed, setCollapsed] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const [openVerificationSubmenu,setOpenVerificationSubmenu]=useState(null);
  const [notification, setNotification] = useState([]);
  const root = "/" + location.pathname.split("/")[1];

  // useEffect(()=>{
  //   (async()=>{
  //     try {
  //       const response = await getRequest(GET_NOTIFICATION_API_URL);
  //       setNotification(response.data.data);
  //       if (JSON.stringify(notification) === JSON.stringify(response.data.data))  {
  //         setShowBadge(false);
  //       }else{
  //         setShowBadge(true);
  //       }
  //     } catch (error) {
        
  //     }
  //   })()
  // },[openVerificationSubmenu])

  const arrayLocation = ["/user/create","/user-profile","/setting/term-of-use/list/:id","/setting/term-of-use/edit/:id","/setting/privacy-policy/list/view/:id","/setting/privacy-policy/create","/setting/privacy-policy/:id","/categories/edit/:id","create","edit",":id", "view","/change-password"];

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  const renderLogoMenu = () => {
    if (collapsed) {
      return <img src="/assets/pwa/icons/favicon-16x16.png" />;
    }
    return (
      <img
        style={{ maxWidth: "120px", height: "64px" }}
        src="/assets/images/logo.svg"
      />
    );
  };
  const renderBadgeTitleVerification = () => {
    if (showBadge && !openVerificationSubmenu) {
      return <Badge className="badge-title"  dot={showBadge} offset={[10, 0]} size="small">Verification</Badge>
    }
    return "Verification"
  };
  const renderBadgeIconVerification = () => {
    if (showBadge && collapsed) {
      return <Badge className="badge-title"  dot={showBadge} offset={[5, 5]} size="small"><VerifiedOutlined /></Badge>
    };
    return <VerifiedOutlined />
  };
  const renderBadgeSubmenu = ()=>{
    if (openVerificationSubmenu & showBadge) {
      return <Link       
      onClick={(e)=>{
        // console.log(e,102)
        setShowBadge(false)
      }}
      to={ROUTE_VERIFICATION_REQUESTS_ACCOUNT}><Badge className="badge-title" dot={showBadge} offset={[10,0]} size="small">Requests</Badge></Link>
    }
    return <Link 
    // onClick={(e)=>console.log(e,107)} 
    to={ROUTE_VERIFICATION_REQUESTS_ACCOUNT}>Requests</Link>
  }
  const items = [
    getItem(<Link to={ROUTE_DASHBOARD}>{renderLogoMenu()}</Link>, "/dash"),
    getItem(
      <Link to={ROUTE_DASHBOARD}>Dashboard</Link>,
      "/",
      <PieChartOutlined />
    ),
    getItem(
      <Link to={ROUTE_USERS}>Users List</Link>,
      "/user",
      <UserOutlined />
    ),
    getItem(
      <Link to={ROUTE_CATEGORIES}>Categories List</Link>,
      "/categories/list",
      <AppstoreOutlined />
    ),
    getItem(
      <Link to={ROUTE_ITEMS}>Items List</Link>,
      "/items",
      <ShoppingCartOutlined />
    ),
    getItem(
      "Collections",
      "/collections",
      <BorderOutlined />,
      [
        getItem(<Link to={ROUTE_COLLECTIONS}>Collections List</Link>,
        "/collections/list"),
        getItem(<Link to={ROUTE_WHITELIST}>Whitelist</Link>,
        "/whitelist")
      ]
    ),
    getItem( renderBadgeTitleVerification(), "/verification",renderBadgeIconVerification()
  , [
      getItem(
        renderBadgeSubmenu(),"/verification/requests/account"),
      getItem(
        <Link to={ROUTE_VERIFICATION_SETTINGS_ACCOUNT_EDIT}>Settings</Link>,"/verification/settings/account/edit"),
    ]),
    getItem(
      "Newsletters",
      "/newsletters/list",
      <MailOutlined />,
      [
        getItem(
          <Link to={ROUTE_NEWSLETTERS}>Newsletters List</Link>,
          "/newsletters"
        ),
        getItem(
          <Link to={ROUTE_SUBSCRIBERS}>Subscribers List</Link>,
          "/subscribers/list"
        ),
      ]
    ),
    getItem("Blogs", "/blogs", <FormOutlined />, [
      getItem(
        <Link to={ROUTE_BLOGCATEGORIES}>Blog Categories</Link>,
        "/blogs/blogCategories/list"
      ),
      getItem(
        <Link to={ROUTE_BLOGLIST}>Blog List</Link>,
        "/blogs/blogList/list"
      ),
    ]),
    getItem("Reports", "/reports", <DatabaseOutlined />, [
      getItem(
        <Link to={ROUTE_REPORT}>Transaction history</Link>,
        "/reports/report-financial"
      ),
      getItem(
        <Link to={ROUTE_SETTING_ACTIVITY_LOG}>Activity Log</Link>,
        "/reports/activity-log/list"
      ),
    ]),
    // getItem("Resources", "/resources", <QuestionCircleOutlined />, [
    //   getItem(
    //     <Link to={ROUTE_RESOURCECATEGORIES}>Resource Categories</Link>,
    //     "/resources/resourceCategories/list"
    //   ),
    //   getItem(
    //     <Link to={ROUTE_RESOURCELIST}>Resource List</Link>,
    //     "/resources/resourceList/list"
    //   ),
    // ]),
    getItem("Settings", "/setting", <SettingOutlined />, [
      getItem(
        <Link to={ROUTE_FEESCONFIG}>Fees Config</Link>,
        "/setting/fees-config"
      ),
      getItem(
        <Link to={ROUTE_SETTING_TERM_OF_USE}>Terms Of Use</Link>,
        "/setting/term-of-use/list"
      ),
      getItem(
        <Link to={ROUTE_SETTING_PRIVACY_POLICY}>Privacy Policy</Link>,
        "/setting/privacy-policy/list"
      ),
    ]),
  ];
  const renderLayoutExtra = ()=>{
    if (arrayLocation.includes(location.pathname) || arrayLocation.includes(location.pathname.split("/")[2])||arrayLocation.includes(location.pathname.split("/")[3]) || arrayLocation.includes(location.pathname.split("/")[4])) {
      return null
    };
    return(
      <Layout className="layout-continue-table" style={{height:"100%" , background:"white",margin: "0 24px", borderRadius:"8px", border: location.pathname === "/" ? "1px solid #dfe0eb" : "none"}}/>
    )
  }
  const onOpenChange =(values)=>{
    let verificaionSubmenu = values.includes('/verification');
    setOpenVerificationSubmenu(verificaionSubmenu);
  };
  return (
    <Style>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          className="site-layout-background"
          collapsible
          theme="dark"
          breakpoint="lg"
          collapsed={collapsed}
          onCollapse={value => setCollapsed(value)}
          width={200}
        >
          <Menu
            mode="inline"
            theme="dark"
            defaultOpenKeys={[root, location.pathname]}
            selectedKeys={[root, location.pathname]}
            style={{
              height: "100%",
              borderRight: 0,
            }}
            onOpenChange={onOpenChange}
            items={items}
          />
        </Sider>
        <Layout>
          <Layout style={{ padding: "0 24px" }}>
            <Content
              className="site-layout-background"
              style={{
                padding: "0 24px",
                margin: 0,
                minHeight: 280,
              }}
            >
              {outlet}
            </Content>
              {renderLayoutExtra()}
          </Layout>
          <Footer
            style={{
              textAlign: "center",
            }}
          ></Footer>
        </Layout>
      </Layout>
    </Style>
  );
});
