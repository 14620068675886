import cookie from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import config from "config";

const APP_UUID_COOKIE = config.UNIQUE_UUID;

export default function () {
  let uniqueId = undefined;
  uniqueId = cookie.get(APP_UUID_COOKIE);
  if (uniqueId) {
    return uniqueId;
  } else {
    uniqueId = uuidv4();
    cookie.set(APP_UUID_COOKIE, uniqueId, { path: "/" });
    return uniqueId;
  }
}
