import { theme,GlobalStyle } from "styles";
import MainRouter from "routes";
import "nprogress/nprogress.css";
import { AuthProvider } from "contexts/auth";
import { Fragment } from "react";
import { ThemeProvider } from "styled-components";

function App() {
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
      <GlobalStyle />
      <AuthProvider>
        <div className="App">
          <MainRouter />
        </div>
      </AuthProvider>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
