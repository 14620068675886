import { getCookie, removeCookie } from "helpers/cookie";
import get from "lodash/get";
import config from "config";
import { ROUTE_LOGIN } from "routes/routesAddress";
import { history } from "routes/history";

export const getToken = () => {
  const cookie = getCookie(config.USER_COOKIE_KEY);
  // console.log("here");
  if (cookie) {
    // console.log("here", cookie);
    return get(JSON.parse(cookie), "accessToken", null);
  } else {
    return undefined;
  }
};
export const getUser = () => {
  const cookie = getCookie(config.USER_COOKIE_KEY);
  if (
    getCookie(config.USER_COOKIE_KEY) !== null &&
    getCookie(config.USER_COOKIE_KEY) !== undefined
  ) {
    return JSON.parse(cookie);
  } else {
    return undefined;
  }
};
export const isAuth = () => {
  const cookie = getCookie(config.USER_COOKIE_KEY);
  if (cookie) {
    const accessToken = get(JSON.parse(cookie), "accessToken", null);
    return !!accessToken;
  } else {
    return false;
  }
};
export const removeCredentials = () => {
  removeCookie(config.USER_COOKIE_KEY);
  removeCookie(config.UNIQUE_UUID);
  window.location.replace(ROUTE_LOGIN);
};
