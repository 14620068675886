export const theme = {
  colors: {
    primary: "#E46400",
    secondaryPrimary: "#363740",
    gray: "#9FA2B4",
    grayBorder: "#DFE0EB",
    black: "#000000",
    white: "#FFFFFF",
    mainBackground: "#E5E5E5",
    secondaryBackground: "#f5f6f7",
    grayLink: "#9FA2B4",
    grayLinkOP80: "#9FA2B480",
    green: "#29CC97",
    red: "#F12B2C",
    sideBarText: "#F4F4EF",
    darkGray: "#252733",
    labelColor:"#3A3C45",
    usernameColor: "#C5C7CD",
  },
  shadows: {
    cardShadows: "rgb(4 17 29 / 25%) 0px 0px 50px 0px",
    secondaryCardShadows: "0px 3px 6px #00000043",
  },
  transitions: {
    defaultTransition: "all 300ms ease",
  },
  transforms: {
    upTransformHover: "translate3d(0px,-2px,0px)",
  },
  gaps: {
    defaultGap: "16px",
  },
  borderRadius: {
    defaultBorderRadius: "10px",
    inputRadius: "10px",
    buttonBorderRadius: "0.4em",
  },
};
