import React from "react";
import { create } from "apisauce";
import {
  HTTP_STATUS_CODE_OK,
  HTTP_STATUS_CODE_NO_CONTENT,
  HTTP_STATUS_CODE_CREATED,
  HTTP_STATUS_CODE_UNAUTHORIZED,
  HTTP_STATUS_CODE_FORBIDDEN,
  HTTP_STATUS_CODE_NOT_FOUND,
  HTTP_STATUS_CODE_SERVER_ERROR,
  HTTP_STATUS_CODE_BAD_REQUEST,
} from "./consts";
import { removeCredentials, getToken } from "helpers/authUtils";
import config from "config";
import { history } from "routes/history";
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from "routes/routesAddress";
import FileDownload from 'js-file-download'
import { showNotification } from "helpers/notification";

export const api = create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});
api.addRequestTransform(request => {
  request.headers["Authorization"] = `Bearer ${getToken()}`;
});
api.addResponseTransform(response => {
  switch (response.status) {
    case HTTP_STATUS_CODE_UNAUTHORIZED:
      removeCredentials();
      break;
    case HTTP_STATUS_CODE_FORBIDDEN:
      history.replace(ROUTE_DASHBOARD);
      showNotification("Error",response.data.message, null, "error");
      break;
    case HTTP_STATUS_CODE_NOT_FOUND:
      showNotification("Error",response.data.message, null, "error");
      break;
    case HTTP_STATUS_CODE_SERVER_ERROR:
      showNotification("Error",response.data.message, null, "error");
      break;
    case HTTP_STATUS_CODE_BAD_REQUEST:
      showNotification("Error",response.data.message, null, "error");
      break;
  }
  if (
    response.status === HTTP_STATUS_CODE_OK ||
    response.status === HTTP_STATUS_CODE_CREATED ||
    response.status === HTTP_STATUS_CODE_NO_CONTENT
  ) {
    return response;
  } else {
    throw response;
  }
});

export async function getRequest(url, params) {
  return api.get(url, params);
}

export async function postRequest(url, body) {
  return api.post(url, body);
}

export async function putRequest(url, body) {
  return api.put(url, body);
}

export async function deleteRequest(url, params) {
  return api.delete(url, params);
}

export async function patchRequest(url, params) {
  return api.patch(url, params);
}
export async function downloadFile(url, params , fileName) {
  const headers = { "Content-Type": "blob" };
  const config = {
    method: "GET",
    url: URL,
    responseType: "arraybuffer",
    headers,
  };
  const response = await api.get(url, params , config);
  FileDownload(response.data, fileName)
}
