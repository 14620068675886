import { getUser } from "helpers/authUtils";

export const initialState = getUser() || {};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...initialState,
        ...action.payload,
      };
    case "LOGOUT":
      console.log("this", initialState);
      return {};
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
