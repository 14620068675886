import cookie from "js-cookie";
import uniqueId from "helpers/generateUniqueId";

export function saveCookie(key, value) {
  const uniqueKey = `${key}_${uniqueId()}`;
  cookie.set(uniqueKey, value, {
    path: "/",
  });
}
export function removeCookie(key) {
  const uniqueKey = `${key}_${uniqueId()}`;
  cookie.remove(uniqueKey);
}
export function getCookie(key) {
  const uniqueKey = `${key}_${uniqueId()}`;
  // console.log(cookie.get(uniqueKey));
  return cookie.get(uniqueKey);
}
