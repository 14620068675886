import { createGlobalStyle } from "styled-components";
import mediaQuery from "./MediaQuery";

export const GlobalStyle = createGlobalStyle`

  link[rel="manifest"] {
     --pwacompat-splash-font: Roboto;
  }
/* BEGIN Regular */
@font-face {
  font-family: Roboto;
  src: url("/assets/fonts/roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("/assets/fonts/roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("/assets/fonts/roboto/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("/assets/fonts/roboto/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: normal; }
/* END Regular */
/* BEGIN Medium */
@font-face {
  font-family: Roboto;
  src: url("/assets/fonts/roboto/Medium/Roboto-Medium.woff2?v=2.137") format("woff2"), url("/assets/fonts/roboto/Medium/Roboto-Medium.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: normal; }
/* END Medium */
/* BEGIN Bold */
@font-face {
  font-family: Roboto;
  src: url("/assets/fonts/roboto/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("/assets/fonts/roboto/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("/assets/fonts/roboto/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("/assets/fonts/roboto/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: normal; }
/* END Bold */
/* BEGIN Black */
@font-face {
  font-family: Roboto;
  src: url("/assets/fonts/roboto/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("/assets/fonts/roboto/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: normal; }
/* END Black */

  html {
    font-size: 16px;
  }
  #root {
    overflow-x: hidden;
    width: 100%;
  }
  body {
    font-family: Mulish, sans-serif;
    overflow-x: hidden;
  }
  #root, body, html {
    height: 100%;
}
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }
  h1,h2 , h3 , h4 , h5 , h6 {
    font-weight: 900;
    line-height: 1;
    margin: 0;  
  }
  .container {
    max-width: 1280px;
    margin: 0 auto;
    z-index: 100;
    position: relative;
    padding: 0 20px;
  }
  .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0 !important;
  }
  .ant-btn{
    border-radius: ${props => props.theme.borderRadius.buttonBorderRadius};
  }
  .ant-col.ant-form-item-control{
    border-radius: ${props => props.theme.borderRadius.inputRadius};
  }
  .btn-right {
    position: absolute;
    right:0px;
    z-index: 121;
    }
    .wrapper{
      .ant-form{
        width:35%;
      }
    }
    .filter-item{
      width: 300px;
    }
  .wrapper-text-popover{
      display: flex;
      justify-content: space-between;
      margin-bottom: 1em;
      div{
        div{
          &:last-child{
            color: #68685c;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
  }
  .wrapper-DEY{
    display: flex;
    gap: 1em;
    font-size: 1.1rem;
    color: #00000050;
    .delete-active{
      color: red;
    }
    .edit-active{
        color: green;
    }
    }
    .ability-to-copy{
      cursor: pointer;
    }
  .active{
    color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};

  }
  .site-statistic-demo-card {
    background: #ececec;
    .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar{
      display: none;
    }
    .ant-btn{display:none}
    .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
      border: none;
    }
    .ant-card-body{
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 1em;
    }
  }
  .wrapper-view{
    display: flex;
    flex-direction: column;
    gap: 1em;
    .site-statistic-demo-card{
      padding: 0;
      background-color: transparent;
      .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
        background-color: transparent;
        min-height:20px;
        padding: 0;
        & [data-placeholder="HTML EDITOR"]{
          font-size: 14px;
          font-weight: 500;
        }
      }
      .ant-card-bordered{
        border: none;
        border-bottom:1px solid  ${props => props.theme.colors.grayLinkOP80};
      }
    }
  }
  .ant-btn-primary,.ant-btn-primary:focus {
    svg{
      color:${props => props.theme.colors.primary};
    }
  }
  .ant-switch-checked{
    .ant-switch-handle {
      left: calc(100% - 7px - 4px);
    }
  } 
  .ant-switch{
    .ant-switch-handle{
      top: -1px;
      left: -3px;
      width: 13px;
      height: 13px;
      &::before{
        border-radius: 5px;
      }
    }
  }
  button.ant-switch{
    background-image: linear-gradient(to right,rgb(255 4 4 / 50%),rgb(255 2 2 / 50%)),linear-gradient(to right,#fff,#fff);  
    .ant-switch-handle::before{
      background-color:${props => props.theme.colors.red};
    }
  }
  button.ant-switch.ant-switch-checked{
    background-color: #29CC9750;
    background-image: linear-gradient(to right,rgb(165 241 173),rgb(157 231 173 / 50%)),linear-gradient(to right,rgb(165 241 173),rgb(165 241 173));
    .ant-switch-handle::before{
      background-color: ${props => props.theme.colors.green};
    }
  }
  .border-ra-10{
    border-radius:10px;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-form-item-control-input-content .ant-picker-range{
    border-radius: 4px;
  }
  .ant-input-group-wrapper .ant-input-wrapper .ant-input{
    border-radius: 4px 0 0 4px;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button{
    border-radius: 0 4px 4px 0;
  }
  .link-column,.deactive-featured, .active-featured{
    svg{
      font-size: 24px;
      color: ${props => props.theme.colors.primary};
    }
  }
  .unverified{
    svg{
      font-size: 24px;
    }
  }
  .ant-form-item-control-input .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-upload-listant-upload-list-picture-card .ant-upload.ant-upload-select-picture-card:hover {
    border-color: ${props => props.theme.colors.primary};
    box-shadow: none;
  }
  .ant-modal-body{
    label{
      color: ${props => props.theme.colors.labelColor};
      font-size: 1.2rem;
      font-weight: 700;
    }
    .wrapper-children{
        .ant-form-item{
            &:first-child{
                display: none;
            }
        }
    }
  }
  .ant-modal .ant-modal-content{
    border-radius: 10px;
    .wrapper-children{
      margin-bottom:4.31em;

    }
    .ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .ant-modal-header{
      border-radius: 10px 10px 0 0;
      border-bottom: 0;
      .ant-modal-title{
        font-size:18px;
        font-weight: 700;
        color:  ${props => props.theme.colors.darkGray};
      }
    }
    .ant-modal-footer{
      border-top: 0;
      padding: 0;
      .ant-form-item{
        margin:0;
      }
        button{
          width: 91px;
          height: 37px;
          border: none;
          color:  ${props => props.theme.colors.white};
          &:first-child{
            background-color:  ${props => props.theme.colors.red};
            &:hover{
              color: ${props => props.theme.colors.red};
              background-color:  ${props => props.theme.colors.white};
              border: 1px solid  ${props => props.theme.colors.red};
            }
          }
          &:last-child{
            background-color:  ${props => props.theme.colors.green};
            &:hover{
              color: ${props => props.theme.colors.green};
              background-color:  ${props => props.theme.colors.white};
              border: 1px solid  ${props => props.theme.colors.green};
            }
          }
          &.ant-btn-default.cancel-btn{
            background-color:  ${props => props.theme.colors.red};
            &:hover{
              color: ${props => props.theme.colors.red};
              background-color:  ${props => props.theme.colors.white};
              border: 1px solid  ${props => props.theme.colors.red};
            }
        }
        &.ant-btn-default.submit-btn{
            background-color:  ${props => props.theme.colors.green};
            &:hover{
              color: ${props => props.theme.colors.green};
              background-color:  ${props => props.theme.colors.white};
              border: 1px solid  ${props => props.theme.colors.green};
            }
        }
        }
    }
  }
  .ant-input-group-addon{
    color:  ${props => props.theme.colors.grayLink};
    opacity: 50;
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    margin: 0;
}
  .button-request{
    width: 100%;
  }
  .chain-logo{
    width:30px;
    margin-right:5px;
  }

`;
export default GlobalStyle;
