import React, { useReducer } from "react";
import { initialState, AuthReducer } from "./reducers";

const AuthContext = React.createContext();

export function useAuthContext() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
}

export const AuthProvider = ({ children }) => {
  const [userData, dispatchUserData] = useReducer(AuthReducer, initialState);

  return (
    <AuthContext.Provider value={{ userData, dispatchUserData }}>
      {children}
    </AuthContext.Provider>
  );
};
