export const ROUTE_LOGIN = "/login";
export const ROUTE_TOW_FACTOR_AUTHENTICATOR = "/tow-factor-authenticator";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_CHANGE_PASSWORD = "/change-password";
export const ROUTE_SET_PASSWORD = "/set-password";
export const ROUTE_EXPIRE_LINK = "/expire-link";
export const ROUTE_DASHBOARD = "/";
export const ROUTE_USER_PROFILE = "/user-profile";
export const ROUTE_USERS = "/user/list";
export const ROUTE_USERS_CREATE = "/user/create";
export const ROUTE_USERS_SINGLE = "/user/:id";
export const ROUTE_USERS_SINGLE_ITEMS = "/user/items/:id";
export const ROUTE_USERS_SINGLE_COLLECTIONS = "/user/collections/:id";
export const ROUTE_USERS_SINGLE_NOTIFICATIONS = "/user/notifications/:id";
export const ROUTE_USERS_SINGLE_ACTIVITY_LOG = "/user/activityLog/:id";
export const ROUTE_USERS_SINGLE_REPORT = "/user/report/:id";
export const ROUTE_COLLECTIONS = "/collections/list";
export const ROUTE_WHITELIST = "/whitelist";
export const ROUTE_WHITELIST_CREATE = "/whitelist/create";
export const ROUTE_WHITELIST_EDIT = "/whitelist/edit/:id";
export const ROUTE_COLLECTIONS_EDIT = "/collections/edit/:id";
export const ROUTE_COLLECTIONS_CREATE = "/collections/create";
export const ROUTE_COLLECTIONS_VIEW = "/collections/view";
export const ROUTE_ITEMS = "/items/list";
export const ROUTE_ITEMS_EDIT = "/items/edit/:id";
export const ROUTE_NEWSLETTERS = "/newsletters/list";
export const ROUTE_SUBSCRIBERS = "/subscribers/list";
export const ROUTE_NEWSLETTERS_RECIPIENT = "/newsletters/view/:id";
export const ROUTE_NEWSLETTERS_CREATE = "/newsletters/create";
export const ROUTE_BLOGCATEGORIES = "/blogs/blogCategories/list";
export const ROUTE_BLOGCATEGORIES_CREATE = "/blogs/blogCategories/create";
export const ROUTE_BLOGCATEGORIES_EDIT = "/blogs/blogCategories/edit/:id";
export const ROUTE_BLOGLIST = "/blogs/blogList/list";
export const ROUTE_BLOGLIST_VIEW = "/blogs/blogList/view/:id";
export const ROUTE_BLOGLIST_CREATE = "/blogs/blogList/create";
export const ROUTE_BLOGLIST_EDIT = "/blogs/blogList/edit/:id";
export const ROUTE_SETTING_ACTIVITY_LOG = "/reports/activity-log/list";
export const ROUTE_REPORT = "/reports/report-financial";
export const ROUTE_RESOURCECATEGORIES = "/resources/resourceCategories/list";
export const ROUTE_RESOURCECATEGORIES_CREATE =
  "/resources/resourceCategories/create";
export const ROUTE_RESOURCECATEGORIES_EDIT =
  "/resources/resourceCategories/edit/:id";
export const ROUTE_RESOURCELIST = "/resources/resourceList/list";
export const ROUTE_RESOURCELIST_ARTICLE_CREATE =
  "/resources/resourceList/create/article";
export const ROUTE_RESOURCELIST_QA_CREATE = "/resources/resourceList/create/qa";
export const ROUTE_RESOURCELIST_ARTICLE_VIEW =
  "/resources/resourceList/view/article/:id";
export const ROUTE_RESOURCELIST_QA_VIEW = "/resources/resourceList/view/qa/:id";
export const ROUTE_RESOURCELIST_ARTICLE_EDIT =
  "/resources/resourceList/edit/article/:id";
export const ROUTE_RESOURCELIST_QA_EDIT = "/resources/resourceList/edit/qa/:id";
export const ROUTE_SETTING_TERM_OF_USE = "/setting/term-of-use/list";
export const ROUTE_SETTING_TERM_OF_USE_VIEW =
  "/setting/term-of-use/list/view/:id";
export const ROUTE_SETTING_TERM_OF_USE_CREATE = "/setting/term-of-use/create";
export const ROUTE_SETTING_TERM_OF_USE_EDIT = "/setting/term-of-use/edit/:id";
export const ROUTE_SETTING_PRIVACY_POLICY = "/setting/privacy-policy/list";
export const ROUTE_SETTING_PRIVACY_POLICY_VIEW =
  "/setting/privacy-policy/list/view/:id";
export const ROUTE_SETTING_PRIVACY_POLICY_CREATE =
  "/setting/privacy-policy/create";
export const ROUTE_SETTING_PRIVACY_POLICY_EDIT =
  "/setting/privacy-policy/edit/:id";
export const ROUTE_FEESCONFIG = "/setting/fees-config";
export const ROUTE_CATEGORIES = "/categories/list";
export const ROUTE_CATEGORIES_EDIT = "/categories/edit/:id";
export const ROUTE_VERIFICATION_REQUESTS_ACCOUNT =
  "/verification/requests/account";
export const ROUTE_VERIFICATION_REQUESTS_COLLECTIONS =
  "/verification/requests/collections";
export const ROUTE_VERIFICATION_SETTINGS_ACCOUNT =
  "/verification/settings/account";
  export const ROUTE_VERIFICATION_SETTINGS_ACCOUNT_EDIT =
  "/verification/settings/account/edit";
  export const ROUTE_VERIFICATION_SETTINGS_ACCOUNT_VIEW =
  "/verification/settings/account/view/:id";
export const ROUTE_VERIFICATION_SETTINGS_COLLECTIONS =
  "/verification/settings/collections";
  export const ROUTE_VERIFICATION_SETTINGS_COLLECTIONS_EDIT =
  "/verification/settings/collections/edit";
  export const ROUTE_VERIFICATION_SETTINGS_COLLECTIONS_VIEW =
  "/verification/settings/collections/view/:id";
