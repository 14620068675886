import { notification } from "antd";

export const showNotification = (title, description, onClick, type) => {
  notification.open({
    message: title,
    description,
    onClick,
    type: type,
    maxCount:1,
  });
};
