import { lazy, Suspense, useEffect } from "react";
import NProgress from "nprogress";
import {
  unstable_HistoryRouter as HistoryRouter,
  Routes,
  Route,
} from "react-router-dom";
import { history } from "routes/history";
import { PrivateOutlet, PublicOutlet } from "./config";
import {
  ROUTE_DASHBOARD,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_ITEMS,
  ROUTE_LOGIN,
  ROUTE_USERS,
  ROUTE_USERS_SINGLE,
  ROUTE_USERS_SINGLE_COLLECTIONS,
  ROUTE_USERS_SINGLE_ITEMS,
  ROUTE_USERS_SINGLE_NOTIFICATIONS,
  ROUTE_USERS_SINGLE_ACTIVITY_LOG,
  ROUTE_USERS_SINGLE_REPORT,
  ROUTE_COLLECTIONS,
  ROUTE_NEWSLETTERS,
  ROUTE_BLOGCATEGORIES,
  ROUTE_BLOGLIST,
  ROUTE_NEWSLETTERS_CREATE,
  ROUTE_BLOGCATEGORIES_CREATE,
  ROUTE_BLOGCATEGORIES_EDIT,
  ROUTE_BLOGLIST_CREATE,
  ROUTE_BLOGLIST_EDIT,
  ROUTE_FEESCONFIG,
  ROUTE_REPORT,
  ROUTE_RESOURCECATEGORIES,
  ROUTE_RESOURCECATEGORIES_CREATE,
  ROUTE_RESOURCECATEGORIES_EDIT,
  ROUTE_RESOURCELIST,
  ROUTE_RESOURCELIST_ARTICLE_CREATE,
  ROUTE_RESOURCELIST_QA_CREATE,
  ROUTE_RESOURCELIST_ARTICLE_EDIT,
  ROUTE_RESOURCELIST_QA_EDIT,
  ROUTE_RESOURCELIST_ARTICLE_VIEW,
  ROUTE_RESOURCELIST_QA_VIEW,
  ROUTE_CHANGE_PASSWORD,
  ROUTE_USER_PROFILE,
  ROUTE_SETTING_TERM_OF_USE,
  ROUTE_SETTING_TERM_OF_USE_CREATE,
  ROUTE_SETTING_TERM_OF_USE_EDIT,
  ROUTE_SETTING_TERM_OF_USE_VIEW,
  ROUTE_SETTING_PRIVACY_POLICY,
  ROUTE_SETTING_PRIVACY_POLICY_CREATE,
  ROUTE_SETTING_PRIVACY_POLICY_EDIT,
  ROUTE_SETTING_PRIVACY_POLICY_VIEW,
  ROUTE_SETTING_ACTIVITY_LOG,
  ROUTE_USERS_CREATE,
  ROUTE_SET_PASSWORD,
  ROUTE_EXPIRE_LINK,
  ROUTE_NEWSLETTERS_RECIPIENT,
  ROUTE_SUBSCRIBERS,
  ROUTE_CATEGORIES,
  ROUTE_CATEGORIES_EDIT,
  ROUTE_BLOGLIST_VIEW,
  ROUTE_TOW_FACTOR_AUTHENTICATOR,
  ROUTE_ITEMS_EDIT,
  ROUTE_WHITELIST ,
  ROUTE_WHITELIST_EDIT,
  ROUTE_COLLECTIONS_EDIT,
  ROUTE_VERIFICATION_REQUESTS_ACCOUNT, 
  ROUTE_VERIFICATION_SETTINGS_ACCOUNT_EDIT,
  ROUTE_VERIFICATION_REQUESTS_COLLECTIONS, 
  ROUTE_VERIFICATION_SETTINGS_COLLECTIONS_EDIT,
  ROUTE_VERIFICATION_SETTINGS_ACCOUNT,
  ROUTE_VERIFICATION_SETTINGS_COLLECTIONS, 
  ROUTE_COLLECTIONS_CREATE, 
  ROUTE_WHITELIST_CREATE, 
  ROUTE_COLLECTIONS_VIEW 
} from "./routesAddress";
import { useAuthContext } from "contexts/auth";
import MainLayout from "../components/layouts/mainLayout";
const LoginPage = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ "pages/auth/login")
);
const TowFactorAuthenticatorPage = lazy(() =>
  import(/* webpackChunkName: "TowFactorAuthenticatorPage" */ "pages/auth/2FA")
);
const ForgotPasswordPage = lazy(() =>
  import(
    /* webpackChunkName: "ForgotPasswordPage" */ "pages/auth/forgotPassword"
  )
);
const ChangePasswordPage = lazy(() =>
  import(
    /* webpackChunkName: "ChangePasswordPage" */ "pages/changePassword"
  )
);
const DashboardPage = lazy(() =>
  import(/* webpackChunkName: "DashboardPage" */ "pages/dashboard")
);
const UsersProfilePage = lazy(() =>
  import(/* webpackChunkName: "UsersProfilePage" */ "pages/userProfile")
);
const UsersPage = lazy(() =>
  import(/* webpackChunkName: "UsersPage" */ "pages/users/list")
);
const UsersCreatePage = lazy(() =>
  import(/* webpackChunkName: "UsersCreatePage" */ "pages/users/create")
);
const SingleUsersPage = lazy(() =>
  import(/* webpackChunkName: "SingleUsersPage" */ "pages/users/singleUser/user")
);
const CollectionsUserPage = lazy(() =>
  import(/* webpackChunkName: "CollectionsUserPage" */ "pages/users/collectionsUser")
);
const ItemsUserPage = lazy(() =>
  import(/* webpackChunkName: "ItemsUserPage" */ "pages/users/itemsUser")
);
const ActivityLogUserPage = lazy(() =>
  import(/* webpackChunkName: "ActivityLogUserPage" */ "pages/users/activityLogUser")
);
const ReportsUserPage = lazy(() =>
  import(/* webpackChunkName: "ReportsUserPage" */ "pages/users/transactionsUser")
);
const NotificationUserPage = lazy(() =>
  import(/* webpackChunkName: "NotificationUserPage" */ "pages/users/notificationsUser")
);
const CollectionsPage = lazy(() =>
  import(/* webpackChunkName: "CollectionsPage" */ "pages/collections/list")
);
const CollectionsCreatePage = lazy(() =>
  import(/* webpackChunkName: "CollectionsPage" */ "pages/collections/create")
);
const CollectionsViewPage = lazy(() =>
  import(/* webpackChunkName: "CollectionsPage" */ "pages/collections/view")
);
const WhitelistPage = lazy(() =>
  import(/* webpackChunkName: "WhitelistPage" */ "pages/collections/whitelist")
);
const WhitelistEditPage = lazy(() =>
import(/* webpackChunkName: "WhitelistPage" */ "pages/collections/whitelist/edit")
);
const WhitelistCreatePage = lazy(() =>
  import(/* webpackChunkName: "WhitelistPage" */ "pages/collections/whitelist/create")
);
const CollectionsEditPage = lazy(() =>
  import(/* webpackChunkName: "CollectionsEditPage" */ "pages/collections/edit")
);
const ItemsPage = lazy(() =>
  import(/* webpackChunkName: "ItemsPage" */ "pages/items/list")
);
const NewslettersPage = lazy(() =>
  import(/* webpackChunkName: "NewslettersPage" */ "pages/newsletters/list")
);
const SubscribersPage = lazy(() =>
  import(/* webpackChunkName: "SubscribersPage" */ "pages/newsletters/subscribers")
);
const NewslettersCreatePage = lazy(() =>
  import(/* webpackChunkName: "TableFormPage" */ "pages/newsletters/create")
);
const NoFoundComponentPage = lazy(() =>
  import(/* webpackChunkName: "NoFoundComponentPage" */ "pages/notFound")
);
const BlogCategoriesPage = lazy(() =>
  import(
    /* webpackChunkName: "BlogCategoriesPage" */ "pages/blogs/blogCategories/list"
  )
);
const BlogCategoriesCreatePage = lazy(() =>
  import(
    /* webpackChunkName: "BlogCategoriesPage" */ "pages/blogs/blogCategories/create"
  )
);
const BlogCategoriesEditPage = lazy(() =>
  import(
    /* webpackChunkName: "BlogCategoriesPage" */ "pages/blogs/blogCategories/edit"
  )
);
const BlogListPage = lazy(() =>
  import(/* webpackChunkName: "BlogListPage" */ "pages/blogs/blogList/list")
);
const BlogListViewPage = lazy(() =>
  import(/* webpackChunkName: "BlogListViewPage" */ "pages/blogs/blogList/view")
);
const BlogListCreatePage = lazy(() =>
  import(
    /* webpackChunkName: "BlogCategoriesPage" */ "pages/blogs/blogList/create"
  )
);
const BlogListEditPage = lazy(() =>
  import(
    /* webpackChunkName: "BlogCategoriesPage" */ "pages/blogs/blogList/edit"
  )
);
const ReportPage = lazy(() =>
  import(
    /* webpackChunkName: "ReportPage" */ "pages/reports/reportTransactions/list"
  )
);
const ResourceCategoriesPage = lazy(() =>
  import(/* webpackChunkName: "ResourcesCategoriesPage" */ "pages/resources/resourceCategories/list")
);
const ResourceCategoriesCreatePage = lazy(() =>
  import(
    /* webpackChunkName: "ResourcesCategoriesCreatePagePage" */ "pages/resources/resourceCategories/create"
  )
);
const ResourceCategoriesEditPage = lazy(() =>
  import(/* webpackChunkName: "ResourcesCategoriesEditPage" */ "pages/resources/resourceCategories/edit")
);
const ResourceListPage = lazy(() =>
  import(/* webpackChunkName: "ResourceListPage" */ "pages/resources/resourceList/list")
);
const ResourceListViewArticlePage = lazy(() =>
  import(/* webpackChunkName: "ResourceListViewArticlePage" */ "pages/resources/resourceList/view/article")
);
const ResourceListViewQAPage = lazy(() =>
  import(/* webpackChunkName: "ResourceListViewQAPage" */ "pages/resources/resourceList/view/qa")
);
const ResourceListArticleCreatePage = lazy(() =>
  import(
    /* webpackChunkName: "ResourceListArticleCreatePage" */ "pages/resources/resourceList/create/article"
  )
);
const ResourceListQACreatePage = lazy(() =>
  import(
    /* webpackChunkName: "ResourceListQACreatePage" */ "pages/resources/resourceList/create/qa"
  )
);
const ResourceListArticleEditPage = lazy(() =>
  import(/* webpackChunkName: "ResourceListEditPage" */ "pages/resources/resourceList/edit/article")
);
const ResourceListQAEditPage = lazy(() =>
  import(/* webpackChunkName: "ResourceListEditPage" */ "pages/resources/resourceList/edit/qa")
);
const TermOfUsePage = lazy(() =>
  import(/* webpackChunkName: "TermOfUsePage" */ "pages/setting/termOfUse/list")
);
const TermOfUseCreatePage = lazy(() =>
  import(
    /* webpackChunkName: "TermOfUseCreatePage" */ "pages/setting/termOfUse/create"
  )
);
const TermOfUseEditPage = lazy(() =>
  import(
    /* webpackChunkName: "TermOfUseEditPage" */ "pages/setting/termOfUse/edit"
  )
);
const TermOfUseViewPage = lazy(() =>
  import(/* webpackChunkName: "TermOfUseViewPage" */ "pages/setting/termOfUse/view")
);
const ActivityLogPage = lazy(() =>
  import(/* webpackChunkName: "ActivityLogPage" */ "pages/reports/activityLog/list")
);
const PrivacyPolicyPage = lazy(() =>
  import(/* webpackChunkName: "PrivacyPolicyPage" */ "pages/setting/privacyPolicy/list")
);
const PrivacyPolicyCreatePage = lazy(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyCreatePage" */ "pages/setting/privacyPolicy/create"
  )
);
const PrivacyPolicyEditPage = lazy(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyEditPage" */ "pages/setting/privacyPolicy/edit"
  )
);
const PrivacyPolicyViewPage = lazy(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyViewPage" */ "pages/setting/privacyPolicy/view"
  )
);
const FeesConfigPage = lazy(() =>
  import(
    /* webpackChunkName: "FeesConfigPage" */ "pages/setting/feesConfig/list"
  )
);
const SetPasswordPage = lazy(() =>
  import(
    /* webpackChunkName: "SetPasswordPage" */ "pages/setPassword"
  )
);
const ExpireLinkPage = lazy(() =>
  import(
    /* webpackChunkName: "ExpireLinkPage" */ "pages/expireLink"
  )
);
const ReciepientPage = lazy(() =>
import(
  /* webpackChunkName: "ReciepientPage" */ "pages/newsletters/recipient"
)
);
const CategoriesPage = lazy(() =>
import(
  /* webpackChunkName: "CategoriesPage" */ "pages/categories/list"
)
);
const CategoriesEditPage = lazy(() =>
import(
  /* webpackChunkName: "CategoriesEditPage" */ "pages/categories/edit"
)
);
const RequestsAccountsPage = lazy(() =>
import(
  /* webpackChunkName: "RequestsAccountsPage" */ "pages/verification/requests/acount"
)
);
const RequestsCollectionsPage = lazy(() =>
import(
  /* webpackChunkName: "RequestsCollectionsPage" */ "pages/verification/requests/collection"
)
);
const SettingsAccountsPage = lazy(() =>
import(
  /* webpackChunkName: "SettingsAccountsPage" */ "pages/verification/settings/acount"
)
);
const SettingsAccountsEditPage = lazy(() =>
import(
  /* webpackChunkName: "SettingsAccountsEditPage" */ "pages/verification/settings/acount/edit"
)
);
const SettingsCollectionsPage = lazy(() =>
import(
  /* webpackChunkName: "SettingsCollectionsPage" */ "pages/verification/settings/collection"
)
);
const SettingsCollectionsEditPage = lazy(() =>
import(
  /* webpackChunkName: "SettingsCollectionsEditPage" */ "pages/verification/settings/collection/edit"
)
);
const LazyLoad = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);
};

const MainRouter = () => {
  const { userData } = useAuthContext();
  function isLoggedin() {
    if (userData.accessToken) {
      return true;
    } else {
      return false;
    }
  }
  const isAuthenticated = isLoggedin();
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route
          path={ROUTE_LOGIN}
          element={
            <Suspense fallback={<LazyLoad />}>
              <PublicOutlet isAuthenticated={isAuthenticated} restricted={true}>
                <LoginPage />
              </PublicOutlet>
            </Suspense>
          }
        />
        <Route
          path={ROUTE_TOW_FACTOR_AUTHENTICATOR}
          element={
            <Suspense fallback={<LazyLoad />}>
              <PublicOutlet isAuthenticated={isAuthenticated} restricted={true}>
                <TowFactorAuthenticatorPage />
              </PublicOutlet>
            </Suspense>
          }
        />
        <Route
          path={ROUTE_FORGOT_PASSWORD}
          element={
            <Suspense fallback={<LazyLoad />}>
              <PublicOutlet isAuthenticated={isAuthenticated} restricted={true}>
                <ForgotPasswordPage />
              </PublicOutlet>
            </Suspense>
          }
        />
        <Route
            path={ROUTE_SET_PASSWORD}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PublicOutlet isAuthenticated={isAuthenticated} restricted={true}>
                  <SetPasswordPage/>
                </PublicOutlet>
              </Suspense>
            }
        />
        <Route
            path={ROUTE_EXPIRE_LINK}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PublicOutlet isAuthenticated={isAuthenticated} restricted={true}>
                  <ExpireLinkPage/>
                </PublicOutlet>
              </Suspense>
            }
          />
        <Route element={<MainLayout />}>
          <Route
            path={ROUTE_DASHBOARD}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <DashboardPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_USER_PROFILE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <UsersProfilePage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_USERS_CREATE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <UsersCreatePage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_CHANGE_PASSWORD}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ChangePasswordPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_USERS}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <UsersPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_USERS_SINGLE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <SingleUsersPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_USERS_SINGLE_ITEMS}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ItemsUserPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_USERS_SINGLE_COLLECTIONS}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <CollectionsUserPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_USERS_SINGLE_ACTIVITY_LOG}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ActivityLogUserPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_USERS_SINGLE_REPORT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ReportsUserPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_USERS_SINGLE_NOTIFICATIONS}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <NotificationUserPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_COLLECTIONS}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <CollectionsPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_COLLECTIONS_EDIT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <CollectionsEditPage />
                </PrivateOutlet>
              </Suspense>
            }
          />

          <Route
            path={ROUTE_COLLECTIONS_CREATE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <CollectionsCreatePage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_COLLECTIONS_VIEW}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <CollectionsViewPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_WHITELIST}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <WhitelistPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_WHITELIST_EDIT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <WhitelistEditPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_WHITELIST_CREATE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <WhitelistCreatePage/>
                </PrivateOutlet>
              </Suspense>
            }
          />        
          <Route
            path={ROUTE_ITEMS}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ItemsPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_NEWSLETTERS}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <NewslettersPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_SUBSCRIBERS}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <SubscribersPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_BLOGCATEGORIES}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <BlogCategoriesPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_BLOGLIST}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <BlogListPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_BLOGLIST_VIEW}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <BlogListViewPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_NEWSLETTERS_CREATE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <NewslettersCreatePage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_NEWSLETTERS_RECIPIENT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ReciepientPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_BLOGCATEGORIES_CREATE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <BlogCategoriesCreatePage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_BLOGCATEGORIES_EDIT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <BlogCategoriesEditPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_BLOGLIST_CREATE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <BlogListCreatePage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_BLOGLIST_EDIT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <BlogListEditPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_FEESCONFIG}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                <FeesConfigPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_REPORT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ReportPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_RESOURCECATEGORIES}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ResourceCategoriesPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_RESOURCECATEGORIES_CREATE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ResourceCategoriesCreatePage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_RESOURCECATEGORIES_EDIT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ResourceCategoriesEditPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_RESOURCELIST}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ResourceListPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_RESOURCELIST_ARTICLE_VIEW}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ResourceListViewArticlePage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_RESOURCELIST_QA_VIEW}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ResourceListViewQAPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_RESOURCELIST_ARTICLE_CREATE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ResourceListArticleCreatePage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_RESOURCELIST_QA_CREATE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ResourceListQACreatePage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_RESOURCELIST_ARTICLE_EDIT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ResourceListArticleEditPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
          path={ROUTE_RESOURCELIST_QA_EDIT}
          element={
            <Suspense fallback={<LazyLoad />}>
              <PrivateOutlet isAuthenticated={isAuthenticated}>
                <ResourceListQAEditPage/>
              </PrivateOutlet>
            </Suspense>
          }
        />
          <Route
            path={ROUTE_SETTING_TERM_OF_USE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <TermOfUsePage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_SETTING_TERM_OF_USE_CREATE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <TermOfUseCreatePage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_SETTING_TERM_OF_USE_VIEW}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <TermOfUseViewPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_SETTING_TERM_OF_USE_EDIT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <TermOfUseEditPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_SETTING_PRIVACY_POLICY}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <PrivacyPolicyPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_SETTING_PRIVACY_POLICY_CREATE}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <PrivacyPolicyCreatePage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_SETTING_PRIVACY_POLICY_EDIT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <PrivacyPolicyEditPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_SETTING_PRIVACY_POLICY_VIEW}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <PrivacyPolicyViewPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_SETTING_ACTIVITY_LOG}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <ActivityLogPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_CATEGORIES}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <CategoriesPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_CATEGORIES_EDIT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <CategoriesEditPage />
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_VERIFICATION_REQUESTS_ACCOUNT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <RequestsAccountsPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_VERIFICATION_REQUESTS_COLLECTIONS}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <RequestsCollectionsPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_VERIFICATION_SETTINGS_ACCOUNT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <SettingsAccountsPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_VERIFICATION_SETTINGS_ACCOUNT_EDIT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <SettingsAccountsEditPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_VERIFICATION_SETTINGS_COLLECTIONS}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <SettingsCollectionsPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
          <Route
            path={ROUTE_VERIFICATION_SETTINGS_COLLECTIONS_EDIT}
            element={
              <Suspense fallback={<LazyLoad />}>
                <PrivateOutlet isAuthenticated={isAuthenticated}>
                  <SettingsCollectionsEditPage/>
                </PrivateOutlet>
              </Suspense>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<LazyLoad />}>
              <NoFoundComponentPage />
            </Suspense>
          }
        />
      </Routes>
    </HistoryRouter>
  );
};

export default MainRouter;
