import mediaQuery from "styles/MediaQuery";
import styled from "styled-components";

export const Style = styled.div`
  .ant-layout.ant-layout-has-sider{
    .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger.site-layout-background{
      background-color: ${props => props.theme.colors.secondaryPrimary};
    }
  }
  ul{
    padding-top: 24px;
    li:nth-child(6).ant-menu-item-selected{
      margin: 0;
    }
  }
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title{
    margin: 0;
  }
  footer,
  .ant-layout {
    background-color: ${props => props.theme.colors.secondaryBackground};
  }
  .page-header {
    padding: 40px 20px;
    .ant-typography {
      text-transform: capitalize;
    }
  }
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-dark.ant-menu-submenu-placement-rightTop
    .ant-menu-dark
    .ant-menu-sub,
  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-dark.ant-menu-submenu-placement-rightTop
    .ant-menu.ant-menu-dark
    .ant-menu-sub,
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected,
  .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
    color: ${props => props.theme.colors.primary};
    background-color: #9fa2b408;
    border-left: 3px solid ${props => props.theme.colors.primary};
    a,
    svg {
      color: ${props => props.theme.colors.primary};
    }
  }
  .ant-layout-sider-trigger {
    background-color: ${props => props.theme.colors.primary};
  }
  .site-layout-background {
    background-color: ${props => props.theme.colors.secondaryBackground};
  }
  .ant-page-header {
    padding: 16px 24px 16px 0;
  }
  .other-side {
    .logout-header {
      color: ${props => props.theme.colors.primary};
    }
    .email-header {
      border-left: 1px solid ${props => props.theme.colors.grayLink};
      padding-left: 1em;
    }
  }
  .ant-menu-dark .ant-menu-submenu-open,
  .ant-menu-dark .ant-menu-submenu-selected {
    .highlight {
      path {
        stroke: #ffffff;
      }
    }
  }
  .ant-menu-submenu.ant-menu-submenu-inline
    .ant-menu-submenu-title[aria-expanded="true"] {
    background: #9fa2b408;
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    li:last-child{
      margin: 0;
    }
    background: #00000030;
  }
  .badge-title{
    color: rgba(255, 255, 255, 0.65);
  }
`;

export default Style;
