import { Navigate, useOutlet } from "react-router-dom";

export function PrivateOutlet({ children, isAuthenticated }) {
  const outlet = useOutlet();
  return isAuthenticated ? children : <Navigate to="/login" />;
}
export function PublicOutlet({ children, isAuthenticated, restricted }) {
  const outlet = useOutlet();
  return isAuthenticated && restricted ? <Navigate to="/" /> : children;
}
